import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
const IndexPage = ({ data }) => {
  return (

    <div id="page">
    <div id="page-wrapper" className="pt-5">


    <div className="mt-5 mb-5 text-center p-2 m-2">
    <h1 className="titling pt-5">Imprint</h1>

    <div class="content section text-center pt-4">
    <p className="text-center">Lilley Mansion LLC<br/>
                        EIN: 83-4405908   <br/>
                        570 Seminole Road Ste 200<br/>
                        Muskegon, MI 49444  </p>
                        </div>
                        </div>

    </div>
    </div>


  )
}

export default IndexPage

IndexPage.Layout = Layout

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
